import React from 'react';

import { IconBasePropsWithColor } from './IconBase';

import { colors } from 'styles/cp';

const QuestionMark: React.FC<IconBasePropsWithColor> = ({
  width = 20,
  height = 20,
  color = colors.gray04,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g id="Web" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Payment-service-fails-(1)" transform="translate(-557.000000, -571.000000)">
          <g id="Group-3" transform="translate(558.000000, 572.000000)">
            <g id="Group-2">
              <circle id="Oval" stroke={color} cx="8" cy="8" r="8"></circle>
              <g id="Group-9" transform="translate(5.333333, 3.555556)" fill={color}>
                <path
                  d="M3.34828102,6.22222222 L1.93721973,6.22222222 L1.93721973,5.76488287 C1.93721973,4.99462712 2.12855007,4.58542876 2.7264574,4.00773694 L3.26457399,3.49022136 C3.56352765,3.2134107 3.81464873,2.9004943 3.81464873,2.41908446 C3.81464873,1.79325167 3.3961136,1.31184182 2.69058296,1.31184182 C1.94917788,1.31184182 1.49476831,1.91360413 1.47085202,2.56350741 L4.54747351e-13,2.43111971 C0.179372197,0.902643456 1.31539611,0 2.78624813,0 C4.16143498,0 5.33333333,0.806361487 5.33333333,2.32280249 C5.33333333,3.34579841 4.87892377,3.80313776 4.13751868,4.45304105 C3.51569507,5.00666237 3.34828102,5.21126155 3.34828102,5.90930582 L3.34828102,6.22222222 Z"
                  id="Path"
                ></path>
                <path
                  d="M1.77777778,8.01154401 C1.77777778,7.51515152 2.17777778,7.11111111 2.66666667,7.11111111 C3.14444444,7.11111111 3.55555556,7.49206349 3.55555556,7.98845599 C3.55555556,8.48484848 3.15555556,8.88888889 2.66666667,8.88888889 C2.2,8.88888889 1.77777778,8.50793651 1.77777778,8.01154401 Z"
                  id="Path"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
export default QuestionMark;
